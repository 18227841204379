import EyeIcon from "../../svg/opened-eye-icon.inline.svg"
import EyeIconClosed from "../../svg/closed-eye-icon.inline.svg"
import React, { useEffect, useState } from "react"
import axios from "../../http/axios"
import { navigate } from "gatsby"

const PasswordConfirm = () => {
  const [passwordTriggered, setPasswordTriggered] = useState(false)
  const [passwordNotValid, setPasswordNotValid] = useState(false)

  const [token, settoken] = useState(false)

  useEffect(() => {
    let url = location.search
    const urlToken = url.substr(1).split("=")[1]
    settoken(urlToken)
  }, [])

  let passwordLengthZero = passwordTriggered ? "password-length-zero" : ""
  let passwordIsNotValid = passwordNotValid ? "password-not-valid" : ""

  //Toggle eye
  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const toggled = passwordShown ? "toggled" : ""

  const [formValues, setFormValues] = useState({
    passwordfirst: "",
    passwordlast: "",
  })

  const handleInputChanges = e => {
    e.persist()
    setPasswordTriggered(false)
    setFormValues(currValues => ({
      ...currValues,
      [e.target.name]: e.target.value,
    }))
    setPasswordNotValid(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const body = {
      resetToken: token,
      newPassword: formValues.passwordfirst,
      repeatedPassword: formValues.passwordlast,
    }
    if (
      formValues.passwordfirst === formValues.passwordlast &&
      formValues.passwordfirst !== "" &&
      formValues.passwordfirst.length > 4
    ) {
      axios
        .post(`/password-recovery/process`, body)
        .then(() => {
          navigate(`/`)
        })
        .catch(() => {
          setPasswordNotValid(true)
          setPasswordTriggered(true)
        })
    } else {
      setPasswordNotValid(true)
      setPasswordTriggered(true)
    }
  }

  return (
    <div>
      <div
        style={{ height: "100vh" }}
        className="side-nav-user-wrapper"
        id="side-nav-user-wrapper"
      >
        <div className="side-nav-user-info-pass">
          <form onSubmit={handleSubmit}>
            <div style={{ color: "black" }} className="form-field-pass">
              <div> Change password</div>
            </div>
            <div>
              <div className="">Please make sure to use secure passwords</div>
            </div>

            <div className="input-group special-group">
              <input
                className={`form-field ${passwordIsNotValid} ${passwordLengthZero}`}
                type={passwordShown ? "text" : "password"}
                name="passwordfirst"
                onChange={handleInputChanges}
                required
                value={formValues.passwordfirst}
              />

              <label>New password</label>
              <EyeIcon
                style={{ top: "20px" }}
                className={`password-eye ${toggled}`}
                id="togglePassword"
                onClick={togglePasswordVisiblity}
              />
              <EyeIconClosed
                style={{ top: "20px" }}
                className={`password-eye crossed-eye-icon ${toggled}`}
                id="togglePassword"
                onClick={togglePasswordVisiblity}
              />
              {passwordNotValid && (
                <span className="error">Invalid password</span>
              )}
            </div>

            <div className="input-group special-group">
              <input
                className={`form-field ${passwordIsNotValid} ${passwordLengthZero}`}
                type={passwordShown ? "text" : "password"}
                name="passwordlast"
                onChange={handleInputChanges}
                required
                value={formValues.passwordlast}
              />
              <label>Verify password</label>
              <EyeIcon
                style={{ top: "20px" }}
                className={`password-eye ${toggled}`}
                id="togglePassword"
                onClick={togglePasswordVisiblity}
              />
              <EyeIconClosed
                style={{ top: "20px" }}
                className={`password-eye crossed-eye-icon ${toggled}`}
                id="togglePassword"
                onClick={togglePasswordVisiblity}
              />
              {passwordNotValid && (
                <span className="error">Invalid password</span>
              )}
            </div>

            <button className="form-field login-button" type="submit">
              Save changes
            </button>
            <button
              className="forgot-password form-field"
              onClick={() =>
                setFormValues({
                  passwordfirst: "",
                  passwordlast: "",
                })
              }
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PasswordConfirm
